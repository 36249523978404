// in src/authProvider.js
// @ts-ignore
import { AUTH_CHECK, AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT, AUTH_GET_PERMISSIONS } from 'react-admin';

import jwt from 'jsonwebtoken';

export default async (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {

    // accept all username/password combinations

    const { username, password } = params;
    localStorage.setItem('username', username);

    let query = `
            mutation loginAdmin($email:String!, $password: String!) {
                loginAdmin(email: $email, password: $password)
            }          
        `

    try {
      const r = await fetch(process.env.REACT_APP_SERVER_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          query,
          variables: { email: username, password: password },
        })
      })

      const data = await r.json()
      // console.log('data returned:', data)
      if (data.errors && data.errors.length > 0) return Promise.reject(data.errors);

      const token = data.data.loginAdmin.replace("Bearer ", "");

      localStorage.setItem('admin_sitandtalk_token', token);
      window.location.href = "/";

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error)
    }

  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('admin_sitandtalk_token');
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('admin_sitandtalk_token');
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return localStorage.getItem('admin_sitandtalk_token')
      ? Promise.resolve()
      : Promise.reject();
  }
  if (type === AUTH_GET_PERMISSIONS) {
    
    const user = jwt.decode(localStorage.getItem('admin_sitandtalk_token'));

    //@ts-ignore
    return user ? Promise.resolve(user.role) : Promise.reject();
  }

  return Promise.reject('Unknown method');
};