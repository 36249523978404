// @ts-nocheck
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import {
  OptionsObject,
  SnackbarProvider,
  SnackbarProviderProps,
  withSnackbar,
  WithSnackbarProps
} from "notistack";
import React from "react";

export class MLDialogProvider extends React.Component<SnackbarProviderProps> {
  render() {
    return (
      <SnackbarProvider {...this.props}>
        <__MLDialog />
        {this.props.children}
      </SnackbarProvider>
    );
  }
}

export interface MLDialogParams {
  title?: string;
  message?: string | any;
  onPositiveClick?: () => boolean | void; // return true for not closing modal
  onNegativeClick?: () => boolean | void; // return true for not closing modal
  positiveText?: string;
  negativeText?: string;
}

export default class MLDialog extends React.Component<WithSnackbarProps> {
  state = MLDialog.defaultState;

  static __uniqueRef: MLDialog;

  static defaultState = {
    open: false,
    title: "",
    message: "",
    onPositiveClick: () => false,
    onNegativeClick: null,
    positiveText: "Ok",
    negativeText: "Cancel"
  };

  static showModal(title: String, message: string | any, params?: MLDialogParams) {
    MLDialog.__uniqueRef.showModal(title, message, params);
  }

  static hideModal() {
    MLDialog.__uniqueRef.hideModal();
  }

  static showSnackbar(message: String, options?: OptionsObject) {
    MLDialog.__uniqueRef.showSnackbar(message, options);
  }

  static hideSnackbar(key: string | number | undefined) {
    MLDialog.__uniqueRef.hideSnackbar(key);
  }

  constructor(props: WithSnackbarProps) {
    super(props);
    MLDialog.__uniqueRef = this;
  }

  showModal(title: String, message: string | any, params?: MLDialogParams) {
    this.setState(
      Object.assign({}, MLDialog.defaultState, params, {
        title: title,
        message: message,
        open: true
      })
    );
  }

  hideModal = () => {
    this.setState({ open: false });
  };

  showSnackbar(message: String, options?: OptionsObject) {
    this.props.enqueueSnackbar(message, options);
  }

  hideSnackbar(key: string | number | undefined) {
    this.props.closeSnackbar(key);
  }

  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={this.hideModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" children={this.state.title} />
        <DialogContent>
          {typeof this.state.message === "string" ? (
            <DialogContentText id="alert-dialog-description" children={this.state.message} />
          ) : (
            this.state.message
          )}
        </DialogContent>
        <DialogActions>
          {this.state.onNegativeClick && (
            <Button
              color="primary"
              children={this.state.negativeText}
              onClick={() => {
                //@ts-ignore
                if (this.state.onNegativeClick && this.state.onNegativeClick()) {
                  // console.log(this.state.onNegativeClick);
                  return;
                }
                this.hideModal();
              }}
            />
          )}

          <Button
            autoFocus
            color="primary"
            children={this.state.positiveText}
            onClick={() => {
              if (this.state.onPositiveClick && this.state.onPositiveClick()) {
                return;
              }
              this.hideModal();
            }}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

const __MLDialog = withSnackbar(MLDialog);
