import React, { useContext } from "react";
//@ts-ignore
import { Datagrid, DateField, Filter, FunctionField, List, ReferenceField, ReferenceInput, SelectInput, TextInput } from "react-admin";
import { languageContext } from "../../App";
import LocalizedField from "../../components/LocalizedField";
import { optionAuthor } from "./optionAuthor";

const ArticleFilter = props => {
  const { locale } = useContext(languageContext);

  return (<Filter {...props}>
    <TextInput label="Search by Title" source="q" alwaysOn />
    <ReferenceInput label="Search By Talk" source="talk" reference="Talk" alwaysOn allowEmpty>
      <SelectInput optionText={({ year, period, number }) => `Year ${year} - ${JSON.parse(period)[locale]} - ${number}`} />
    </ReferenceInput>
  </Filter>
  )
};

export const ArticleList = props => {
  return (<List filters={<ArticleFilter />} {...props}>
    <Datagrid rowClick="edit">
      <LocalizedField source="title" />
      <LocalizedField source="subtitle" />
      <ReferenceField source="talk" reference="Talk" linkType={false}>
        <FunctionField
          render={source => {
            return <languageContext.Consumer>
              {({ locale }) => {
                let period;
                try {
                  period = JSON.parse(source.period)[locale];
                } catch (error) {
                  period = source.period;
                }
                return (`Year ${source.year} - ${period} - Number ${source.number}`)
              }}
            </languageContext.Consumer>
          }}
        />
      </ReferenceField>
      <ReferenceField source="author" reference="Author" linkType={false}>
        <FunctionField render={source => optionAuthor(source, props.client)} />
      </ReferenceField>
      <DateField source="created_at" showTime />
    </Datagrid>
  </List>
  );
}
