import {
  Button,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import gql from "graphql-tag";
import React, { useCallback, useState } from "react";
import { useApolloClient } from "react-apollo-hooks";
import { useDropzone } from "react-dropzone";
import XLSX from "xlsx";

const Dashboard = (props) => {
  const [sheets, setSheets] = useState([]);
  const [totalSh, setTotalSh] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dropped, setDropped] = useState(false);
  const [parseErrors, setParseErrors] = useState([]);

  const client = useApolloClient(props.client);

  const [fileNames, setFileNames] = useState([]);
  let dataEntry = [];

  const onDrop = useCallback((rawFiles) => {
    setDropped(true);
    setLoading(true);

    const acceptedFiles = rawFiles.filter(
      (x) => x.path.endsWith(".xlsx") || x.path.endsWith(".ods")
    );

    const tmpSheets = new Array(acceptedFiles.length);
    setTotalSh(acceptedFiles.length);

    const promises = [];

    setFileNames(acceptedFiles.map((x) => x.path));

    // acceptedFiles.map(x => console.log(x.path));

    acceptedFiles.forEach((f, i) => {
      //! crea la la promise

      promises.push(
        new Promise((resolve, reject) => {
          var reader = new FileReader();
          reader.onload = function (e) {
            //? risolve la promise
            //@ts-ignore
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: "array" });

            var sheet = workbook["Sheets"]["Sheet1"];

            tmpSheets[i] = sheet;
            resolve(i);
          };
          reader.readAsArrayBuffer(f);
        })
      );
    });

    Promise.all(promises).then((values) => {
      setLoading(false);

      setSheets([...tmpSheets]);
    });

    // ! la promise ALL setta lo stato
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function renderSwitchTable(sheet, indexSheet, canSetErrors) {
    if (!sheet || sheet == undefined) {
      return;
    }
    const keys = Object.keys(sheet);
    if (!keys.length) return null;

    return renderTableV2(sheet, indexSheet, canSetErrors);
  }

  function renderTableV2(sheet, indexSheet, canSetErrors) {
    console.log(sheet);
    if (!sheet || sheet == undefined) {
      return;
    }
    const keys = Object.keys(sheet);
    if (!keys.length) return null;

    const filteredKeys = keys.filter((x) => {
      const letter = x[0];
      const number = parseInt(x.substring(1));

      return number > 1;
    });

    const orderedLetters = {};

    filteredKeys.forEach((x) => {
      const letter = x[0];
      const number = parseInt(x.substring(1));

      if (!orderedLetters[number]) orderedLetters[number] = [];

      const letters = orderedLetters[number];
      if (letters.indexOf(letter) === -1) letters.push(letter);
    });

    // console.log(orderedLetters);

    const keysOrderedLetters = Object.keys(orderedLetters);

    keysOrderedLetters.forEach((x, i) => {
      //! x --> riga della tabella

      //! arrLett sono i valori effettivi scritti nel file
      const arrLett = orderedLetters[x];

      function getValue(letter, format = "v") {
        return arrLett.indexOf(letter) > -1 ? sheet[letter + x][format] : null;
      }

      const fullName = getValue("A"); // ! da testare
      const email = getValue("B");

      const entry = {
        full_name: fullName,
        email: email,
      };

      if (fullName || email) dataEntry.push(entry);
    });

    const processati = dataEntry.map((x, i) => {
      return (
        <TableRow key={i}>
          <TableCell align="right">{x.full_name}</TableCell>
          <TableCell align="right">{x.email}</TableCell>
        </TableRow>
      );
    });

    return processati;
  }

  return (
    <>
      <Typography variant="headline" children={"Importing users"} />

      <div
        style={{
          border: "4px dashed #003A78",
          maxWidth: "90%",
          background: "rgba(0, 58, 120, .02)",
          height: 200,
          textAlign: "center",
          display: "table",
          marginBottom: 16,
          marginTop: 16,
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p style={{ display: "table-cell", verticalAlign: "middle" }}>
            Drop the import files here..
          </p>
        ) : (
          <p style={{ display: "table-cell", verticalAlign: "middle" }}>
            Drag the import files here
          </p>
        )}
      </div>

      {dropped && (
        <Paper style={{ width: 600, maxWidth: "90%", padding: 16 }}>
          <Grid container direction="row" justify="space-between">
            <Typography variant="headline" children={"Loading"} />
            {!loading && (
              <>
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => {
                    setSheets([]);
                    setTotalSh(0);
                    setLoading(false);
                    setDropped(false);
                    setParseErrors([]);
                    dataEntry = [];
                  }}
                  children="DELETE DATA"
                />
                <Button
                  color="primary"
                  variant="contained"
                  disabled={Boolean(parseErrors.length)}
                  onClick={() => {
                    setLoading(true);
                    client
                      .mutate({
                        variables: { input: dataEntry },
                        mutation: gql(`
                          mutation batchUser($input: [InputBatchUser]){
                            batchUser(input: $input)
                          }`),
                      })
                      .then((value) => {
                        alert("Sent with success!");

                        setSheets([]);
                        setTotalSh(0);
                        setLoading(false);
                        setDropped(false);

                        setParseErrors([]);
                        dataEntry = [];
                      })
                      .catch((error) => {
                        alert("Error: ask to info@sitgroup.it");
                      });
                  }}
                  children="IMPORT USERS"
                />
              </>
            )}
          </Grid>
          <Typography children={`${totalSh} file processat${totalSh > 1 ? "i" : "o"}`} />
          {loading && <LinearProgress />}
        </Paper>
      )}

      {sheets && sheets.length > 0 && (
        <Paper
          style={{
            width: "100%",
            maxHeight: 650,
            marginTop: 24,
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <Table style={{ minWidth: 700 }}>
            <TableHead>
              <TableRow>
                <TableCell align="right">Full name</TableCell>
                <TableCell align="right">Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sheets.filter((x) => x).map((x, i, arr) => renderSwitchTable(x, i, arr.length))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </>
  );
};

export default Dashboard;
