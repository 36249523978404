import { Button, CircularProgress } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useState } from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useApolloClient } from "react-apollo-hooks";
import { useDropzone } from "react-dropzone";
import MLDialog from "../../../../components/MLDialog";
import Topbar from "./TopBar";
import { Field } from "redux-form";
import PropTypes from "prop-types";

const uuidv1 = require("uuid/v1");

const FieldComp = (props) => {
  const { input, image } = props;

  useEffect(() => {
    if (image && input.value !== image) input.onChange(image);
  }, [image]);

  return <div></div>;
};

/**
 * Image Picker for article body and profile image.
 */
const ImagePicker = (props) => {
  const [loading, setLoading] = useState(false);
  const { provided, onSave, element, record, source, fullSize, style } = props;

  const client = useApolloClient(props.client);

  const [responseApollo, setResponseApollo] = useState();
  const [reduxFile, setReduxFile] = useState();

  if (record && source) {
    let imageId = record[source];

    // console.log("IMAGE", imageId);

    if (!responseApollo && imageId && typeof imageId === "string") {
      client
        .query({
          query: gql`
            query getImage($id: ID!) {
              Image(id: $id) {
                id
                url
                thumb_url
              }
            }
          `,
          variables: { id: imageId },
        })
        .then((response) => {
          if (response.data && response.data.Image) {
            setResponseApollo(response.data.Image);
          }
        });
    }
  }

  const [image, setImage] = useState(element && element.content);

  useEffect(() => {
    if (responseApollo) setImage(responseApollo);
  }, [responseApollo]);
  useEffect(() => {
    if (element && element.content) {
      setImage(element.content);
    }
  }, [element]);
  useEffect(() => {
    if (image && image !== (element && element.content) && onSave) onSave(image);
  }, [image]);

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    setLoading(true);

    if (acceptedFiles.length !== 1) {
      MLDialog.showSnackbar(
        "Only the first image will be loaded. If it is necessary to load more than one image, use the Carosello component."
      );
    }

    let image = acceptedFiles[0];

    if (record && source) {
      record[source] = image;
    }

    let imageType = /image.*/;
    if (image.type.match(imageType)) {
      let reader = new FileReader();
      reader.onload = ((imageFile) => {
        return (e) => {
          if (source) {
            setImage({
              id: uuidv1(),
              url: e.target.result,
              thumb_url: e.target.result,
            });

            setReduxFile(imageFile);
          } else {
            //!OFFLINE
            client
              .mutate({
                variables: { file: imageFile },
                mutation: gql(`
              mutation uploadImage($file: Upload!) {
                uploadImage(file: $file) {
                  url
                  thumb_url
                }
              }
            `),
              })
              .then((value) => {
                setImage({
                  id: uuidv1(),
                  url: value.data.uploadImage.url,
                  thumb_url: value.data.uploadImage.thumb_url,
                });
                setLoading(false);
              })
              .catch((err) => {
                console.error(err);
                setLoading(false);
                MLDialog.showSnackbar(
                  "An error occurred while loading the image, please try again"
                );
              });
          }
        };
      })(image);
      reader.readAsDataURL(image);
    } else {
      console.log("Tipo di file non supportato");
      MLDialog.showSnackbar("Please upload an image type file");
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: loading,
    accept: "image/*",
  });

  return (
    <div
      css={css`
        border-radius: 12px;
        border: ${fullSize ? "0px" : "1px solid rgba(0, 0, 0, 0.12);"};
        padding-top: 8px;
      `}
      style={style || {}}
    >
      <Topbar name="Image selector" provided={provided} onDelete={props.onDelete} />

      {source && (
        <Field
          name={source}
          component={(nprops) => <FieldComp {...nprops} image={reduxFile} />}
          type="text"
        />
      )}
      {!image ? (
        <div
          className="image-selector"
          css={css`
            width: ${fullSize ? "calc(100% - 48px)" : "20%"};
            min-width: 192px;
            max-width: 100%;
            min-height: 256px;
            padding-top: ${fullSize ? 48 : 24}%;
            margin-left: 24px;
            margin-right: 24px;
            margin-bottom: 24px;
            box-sizing: border-box;
            position: relative;
            outline: none;
          `}
          {...getRootProps()}
        >
          <input className="image-selector-drop" {...getInputProps()} />

          <div className={`image-selector-container ${isDragActive ? "active" : ""}`}>
            <div className="content">
              {!loading ? (
                <>
                  <Button
                    variant="fab"
                    disabled={!isDragActive}
                    color="primary"
                    aria-label="Cloud Upload"
                  >
                    <CloudUploadIcon />
                  </Button>
                  <p>{props.label || "Upload image"}</p>
                </>
              ) : (
                <>
                  <CircularProgress size={24} />
                  <p>I am uploading the image</p>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className="image-container"
          css={css`
            width: 100%;
            position: relative;
            text-align: ${fullSize ? "center" : "left"};
          `}
        >
          <img
            alt=""
            css={css`
              width: ${fullSize ? "auto" : "20%"};
              min-width: 192px;
              max-width: 100%;
              height: auto;
              min-height: 96px;
              max-height: ${fullSize ? "480px !important" : "240px !important"};
              border-radius: 12px;
              object-fit: cover;
              margin-left: 24px;
              margin-right: 24px;
            `}
            src={image.url}
          />
          <div className="description">
            {!source && (
              <span>
                <input
                  name="image-desc"
                  type="text"
                  value={image.caption || ""}
                  onChange={(event) => {
                    const text = event.target.value;
                    setImage({ ...image, caption: text });
                  }}
                  placeholder="Enter a description for this image"
                  className="image-description-input"
                />
              </span>
            )}
            <Button
              color="primary"
              onClick={() => {
                setImage(undefined);
                setLoading(false);
              }}
            >
              Replace image
            </Button>
            {/* TODO add snack here */}
          </div>
        </div>
      )}
    </div>
  );
};

ImagePicker.propTypes = {
  /** Provided required for drag and drop and drag handler. Ciao Tiziano!! */
  provided: PropTypes.any,
};

export default ImagePicker;
