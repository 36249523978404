import React from "react";
import RichText from "./RichTextEditor";

const ArticleRichText = props => {

  const { provided, onDelete, onSave, value } = props;

  return (
    <div className="draggable-container">
      <div className="draggable-content">
        <RichText
          onSave={value =>
            onSave(value)
          }
          key={"a"}
          element={value}
          onDelete={onDelete}
          name="Block of text"
          provided={provided}
          {...props}
        />
      </div>
    </div>
  );
}

export default ArticleRichText;
