
import React from "react";
import ImageCarousel from "./ImageCarousel";

const ArticleCarousel = props => {

  const { provided, value } = props;

  return (
    <div className="draggable-container">
      <div className="draggable-content">
        <ImageCarousel {...props} element={value} provided={provided} />
      </div>
    </div>
  )
};

export default ArticleCarousel;
