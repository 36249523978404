import React from "react";
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

const WelcomeMessage = props => {
  const { options, type } = props;


  return (
    <p
      css={css`
        font-size: 24px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.87);
      `}
    >
      {type === "create" ? "Add" : "Edit"} {options.single}
    </p>
  );
};

export default WelcomeMessage;
