/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Grid, Switch, Typography } from "@material-ui/core";
import VideoIcon from "@material-ui/icons/PersonalVideo";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Topbar from "../TopBar";

const VideoPicker = (props: any) => {
  const { provided, onSave, element, source, fullSize, style } = props;

  const [video, setVideo] = useState<{ url: string; caption: string; streamVideo: boolean }>(
    (element && element.content) || {}
  );

  useEffect(() => {
    if (element && element.content) {
      setVideo(element.content);
    }
  }, [element]);

  useEffect(() => {
    if (video && video !== (element && element.content) && onSave) onSave(video);
  }, [video]);

  return (
    <div
      css={css`
        border-radius: 12px;
        border: ${fullSize ? "0px" : "1px solid rgba(0, 0, 0, 0.12);"};
        padding-top: 8px;
      `}
      style={style || {}}
    >
      <Topbar name="Video selector" provided={provided} onDelete={props.onDelete} />

      <div
        className="image-container"
        css={css`
          width: 100%;
          position: relative;
          text-align: ${fullSize ? "center" : "left"};
        `}
      >
        <Grid container alignItems="flex-end" css={{ marginBottom: 20 }}>
          <Grid item xs={8}>
            <input
              type="text"
              placeholder="Video URL or Microsoft Stream Embed IFrame"
              className="image-description-input"
              css={{
                paddingInline: 40,
                paddingBlock: 12,
                color: "#003A78",
                border: "1px solid rgba(0, 58, 120, 0.16)",
                borderRadius: 12,
                width: "calc(100% - 120px) !important",
                marginTop: 20,
              }}
              value={video?.url || ""}
              onChange={(e) => setVideo((v) => ({ ...v, url: e.currentTarget.value }))}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography children="Is Microsoft StreamVideo?" />
            <Switch
              checked={video?.streamVideo || false}
              onChange={(e) => setVideo((v) => ({ ...v, streamVideo: e.currentTarget.checked }))}
            />
          </Grid>
        </Grid>

        <Grid container justify="center" css={{ paddingBlock: 20 }}>
          {video.url ? (
            video?.streamVideo ? (
              <div dangerouslySetInnerHTML={{ __html: video.url }} />
            ) : (
              <ReactPlayer
                style={{ borderRadius: 12, overflow: "hidden" }}
                spacing={2}
                controls
                url={video?.url || ""}
              />
            )
          ) : (
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              css={{ paddingBlock: 60 }}
            >
              <Grid
                container
                alignItems="center"
                justify="center"
                css={{
                  backgroundColor: "rgba(0, 58, 120, 0.16)",
                  borderRadius: 60,
                  width: 60,
                  height: 60,
                  marginBottom: 12,
                }}
              >
                <VideoIcon />
              </Grid>
              <div>Write a video url to preview the content</div>
            </Grid>
          )}
        </Grid>

        <div className="description">
          {!source && (
            <span>
              <input
                name="image-desc"
                type="text"
                value={video?.caption || ""}
                onChange={(event) => {
                  const text = event.target.value;
                  setVideo({ ...video, caption: text });
                }}
                placeholder="Enter a description for this video"
                className="image-description-input"
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

VideoPicker.propTypes = {
  /** Provided required for drag and drop and drag handler. Ciao Tiziano!! */
  provided: PropTypes.any,
};

export default VideoPicker;
