import PERMISSION from "./permission";
import { traduction } from "./trad";
import { css } from "@emotion/core";

export const ql = (data, path) => {
  if (path == null || path == undefined) return null;
  let tokens = path.split(".");
  let tmp = data;
  let index = 0;
  while (tmp != null && tmp != undefined) {
    if (index == tokens.length) break;
    try {
      tmp = tmp[tokens[index]];
    } catch (err) {
      console.log("######### $err");
      break;
    }
    index++;
  }
  return tmp;
};

export const L = trad => {
  //! get language
  const lang = "it";

  const getTrad = ql(traduction, `${lang}.${trad}`);
  if (getTrad) return getTrad;
  if (
    process.env.NODE_ENV !== "production" &&
    process.env.REACT_APP_SHOW_MISSING_TRAD == "true"
  )
    console.warn("Traduzione mancante per " + trad + " in lingua " + lang);
  return trad;
};

export const maleFemale = [
  { name: "Maschile", value: "male" },
  { name: "Femminile", value: "female" }
];

export const isValidDate = d => {
  // console.log(d, d instanceof Date, !isNaN(d));
  //@ts-ignore
  return d instanceof Date && !isNaN(d);
};

export const fixData = (date) => {

  if (!date) return date;

  //? 31/01/2018

  //* 01/31/2018

  let values = date.split("/");
  let tmp = values[1];

  values[1] = values[0];
  values[0] = tmp;

  return values.join("/");

}

export const permissionLevel = permission => {
  let level = 0;
  switch (permission) {
    case PERMISSION.SuperAdmin:
      level = 100;
      break;
    case PERMISSION.SitAdmin:
      level = 90;
      break;
    case PERMISSION.Editor:
      level = 20;
      break;
    default:
      level = 0;
      break;
  }

  return level;
};


export const isSmallDevice = () => {
  return window.innerWidth <= 500;
}

export const hexToRGBA = (hex, alpha) => {
  let c;

  // Check if hex string is a valid color
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    // Remove # from the hex string and split in a array
    c = hex.substring(1).split("");

    // Eventually convert hex shortcut to full (e.g. #fff to #ffffff)
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }

    c = `0x${c.join("")}`;
    return `rgba(${(c >> 16) & 255}, ${(c >> 8) & 255}, ${c & 255}, ${alpha})`;
  }
  throw new Error("Invalid HEX on RGBA conversion");
};

export const getBrightnessFromHEX = (hex) => {

  // console.log(hex);

  let c;

  // Check if hex string is a valid color
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    // Remove # from the hex string and split in a array
    c = hex.substring(1).split("");

    // Eventually convert hex shortcut to full (e.g. #fff to #ffffff)
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }

    c = `0x${c.join("")}`;

    const brightness = Math.round(((parseInt((c >> 16) & 255) * 299) +
      (parseInt(c >> 8) * 587) +
      (parseInt(c & 255) * 114)) / 1000);

    return brightness;
  }
  return 0;
}




export const horizontalSimpleFormCss = () => {
  return css`
          .ra-input{
            width: 33.333%;
            display: inline-block;
            margin-bottom: 48px;

            @media screen and (max-width: 800px) {
              width: auto;
              display: block;
              margin-bottom: 16px;
            }
          }
        `
}