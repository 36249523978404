import React, { useContext } from "react";
//@ts-ignore
import { Create, SimpleForm, FormDataConsumer } from "react-admin";
import AddableReferenceWithSelectInput from "../../components/AddableReferenceSelectInput";
import LocalizedInput from "../../components/LocalizedInput";
import ThreeInARow from "../../components/ThreeInARow";
import WelcomeMessage from "../../components/WelcomeMessage";
import ArticleComposer from "./Components/Composer";
import { optionAuthor } from "./optionAuthor";
import { optionRender } from "./optionRender";
import { optionRubric } from "./optionRubric";
import { optionTalk } from "./optionTalk";
import { languageContext } from "../../App";
import { nominativeArticles } from './edit';

export const ArticleCreate = props => {

  const { locale } = useContext(languageContext);
  return (
    <Create {...props} title="Crea Articolo">
      <SimpleForm className="no-card">
        <WelcomeMessage options={props.options} type={"create"} />
        {/* <TitleInput />
      <SubtitleInput /> */}

        {/* <TitleInput /> */}
        <LocalizedInput component="input" source="title" placeholder="Enter title" className="title-input" fullWidth />
        {/* <SubtitleInput /> */}
        <LocalizedInput component="input" source="subtitle" placeholder="Insert subtitle" className="subtitle-input" fullWidth />

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const localizedTitle = JSON.parse(formData.title)[locale] || "";
            const lowercaseTitle = (localizedTitle || "").toLowerCase()
            const noSpecialChars = lowercaseTitle.replace(/('|"|,|;|:|\.|\?|!|\/|=)/g, " ");
            const splittedTitle = noSpecialChars.split(" ");
            const noArticlesTitle = splittedTitle.filter(name => !nominativeArticles.includes(name) && name.length > 0);
            const noSpacesTitle = noArticlesTitle.join("-");

            const parsedTitle = noSpacesTitle;

            return <LocalizedInput disabled value={parsedTitle} controlled source="slug" label="Slug" placeholder="Slug" fullWidth />
          }}
        </FormDataConsumer>

        <LocalizedInput component="switch" source="published" label="Published" />

        <ThreeInARow>
          <AddableReferenceWithSelectInput
            optionText={choice => optionAuthor(choice, props.client)}
            reference="Author"
            source="author"
            label="Author"
          />

          <AddableReferenceWithSelectInput
            optionText={optionRubric}
            reference="Rubric"
            source="rubric"
            label="Rubric"
          />

          <AddableReferenceWithSelectInput
            optionText={optionTalk}
            reference="Talk"
            source="talk"
            label="Talk"
          />
        </ThreeInARow>

        <AddableReferenceWithSelectInput
          multi
          fullWidth
          optionText={optionRender}
          reference="Topic"
          label="Topic"
          source="topics"
        />

        <ArticleComposer {...props} />
      </SimpleForm>
    </Create>
  )
}
