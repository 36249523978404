import React from "react";
//@ts-ignore
import { DateField, Edit, SimpleForm, TextInput } from "react-admin";
import ThreeInARow from "../../components/ThreeInARow";
import WelcomeMessage from "../../components/WelcomeMessage";
import ImagePicker from "../Article/Components/Composer/ImagePicker";

export const AuthorEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <WelcomeMessage options={props.options} type={"edit"} />
      <ThreeInARow>
        <TextInput fullWidth source="name" placeholder="Mario" />
        <TextInput fullWidth source="surname" placeholder="Rossi" />
        <TextInput
          fullWidth
          source="caption"
          label="Short description"
          placeholder="Project Manager"
        />
      </ThreeInARow>

      <ImagePicker style={{ marginTop: 16, marginBottom: 16 }} source="profile_image" label="Picture profile" />
  
      <DateField disabled source="updated_at" showTime />
    </SimpleForm>
  </Edit>
);
