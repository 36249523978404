import { Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { languageContext } from "../../App";

const LocalizedField = (props) => {
  const { record, source, style, gutterBottom } = props;

  const { locale } = useContext(languageContext);
  let value;
  try {
    value = JSON.parse(record[source])[locale];
  } catch (error) {
    value = record[source];
  }

  return (
    <Typography
      style={style}
      variant="body1"
      children={value || "Trad 🛑"}
      gutterBottom={gutterBottom}
    />
  );
};

export default LocalizedField;
