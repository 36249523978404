import gql from "graphql-tag";
import React from "react";
import { Query } from "react-apollo";
export const optionAuthor = (choice: any, client: any) => (
  <div
    key={choice.name + "-author"}
    style={{
      padding: 10,
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between"
    }}
  >
    {choice.name} {choice.surname}
    {choice.profile_image && (
      <Query
        client={client}
        query={gql`
          query Image($id: ID!) {
            Image(id: $id) {
              title
              thumb_url
              url
            }
          }
        `}
        variables={{ id: choice.profile_image }}
      >
        {({ data, loading, error }: any) => {
          if (loading || error || !data) return null;
          return (
            <img
              style={{ borderRadius: 40, objectFit: "cover" }}
              alt={choice.name + " " + choice.surname}
              height={30}
              width={30}
              src={data?.Image?.thumb_url || ""}
            />
          );
        }}
      </Query>
    )}
  </div>
);
