import React from "react";
// @ts-ignore
import { Datagrid, DateField, List, TextField, BooleanField, Filter, TextInput } from "react-admin";

const UserFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by name" source="full_name" alwaysOn />
    <TextInput label="Search by email" source="email" alwaysOn />
  </Filter>
);

export const UserList = props => (
  <List filters={<UserFilter />} {...props} >
    <Datagrid rowClick="edit">
      <BooleanField source="email_confirmed.status" label="Verified" />
      <DateField source="email_confirmed.confirmed_at" label="Checked on" />
      <TextField source="full_name" label="Full name" />
      <TextField source="email" />
      <DateField source="created_at" showTime />
    </Datagrid>
  </List>
);
