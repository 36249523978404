import React from "react";
import { languageContext } from "../../App";
export const optionTalk = choice => {
  return (
    <languageContext.Consumer>
      {({ locale }) => {

        let period;
        try {
          period = JSON.parse(choice.period)[locale];
        } catch (error) {
          period = choice.period;
        }
        return (<div
          key={choice.number + "-talk"}
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            padding: 10,
            justifyContent: "space-between",
            height: 35
          }}
        >
          {choice.name}
          {choice.year && `Year ${choice.year} - ${period} - Number ${choice.number}`}
        </div>)

      }}
    </languageContext.Consumer>
  )
};
