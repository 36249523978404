import React from "react";
//@ts-ignore
import { Edit, SimpleForm, TextInput } from "react-admin";
import { ColorInput } from "react-admin-color-input";
import ThreeInARow from "../../components/ThreeInARow";
import WelcomeMessage from "../../components/WelcomeMessage";
import { smallScreenStyle } from "./create";
import LocalizedInput from "../../components/LocalizedInput";

export const RubricEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <WelcomeMessage options={props.options} type={"edit"} />
      <ThreeInARow>
        <LocalizedInput fullWidth source="name" required />
        {/* <TextInput fullWidth label="Nome" source="name" required /> */}
        <TextInput fullWidth label="Slug" source="short_name" required />
        <ColorInput style={smallScreenStyle} label="Primary color" source="foreground_color" required />
        <ColorInput style={smallScreenStyle} label="Background color (reading)" source="background_color" required />
      </ThreeInARow>
    </SimpleForm>
  </Edit>
);
