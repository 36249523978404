import Color from "./colors";
import { createMuiTheme } from "@material-ui/core";

export default createMuiTheme({

  palette: {
    primary: {
      light: Color.primaryLight,
      main: Color.primary,
      dark: Color.primary,
      contrastText: Color.primaryText
    },
    secondary: {
      main: Color.accent,
      light: Color.accentLight, //wip
      dark: Color.accentDark,
      contrastText: Color.primaryText
    }
  }
});
