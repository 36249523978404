import React from "react";
import { languageContext } from "../../App";
export const optionRender = choice => {
  return (
    <languageContext.Consumer>
      {({ locale }) => {

        let name;
        try {
          name = JSON.parse(choice.name)[locale];
        } catch (error) {
          name = choice.name
        }

        return (<div
          key={choice.number + "-render"}
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            padding: 10,
            justifyContent: "space-between",
            height: 35
          }}
        >
          {name}
        </div>);
      }}
    </languageContext.Consumer>)
};
