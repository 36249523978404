export default Object.freeze({
  accent: '#003A78',
  accentLight: '#96d8cd',
  accentText: '#FFF',
  accentDark: '#041c19',
  primary: '#003A78',
  primaryLight: '#003A78',
  primaryDark: '#be4d44',
  primaryText: '#FFFFFF',
  secondaryLighter: '#EFEFEF',//dfdfdf
  secondaryLight: '#b7c2c2',
  secondaryDark: '#676d72',
  background: '#fafafa',
  border: '#dfdfdf',
});