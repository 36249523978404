import React from "react";
import ImagePicker from "./ImagePicker";

const ArticleImage = props => {
  const { value, provided } = props;

  return (
    <div className="draggable-container">
      <div className="draggable-content">
        <ImagePicker fullSize {...props} element={value} provided={provided} />
      </div>
    </div>
  );
};

export default ArticleImage;
