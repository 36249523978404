import React from "react";
//@ts-ignore
import { Show, SimpleShowLayout, TextField, ReferenceManyField, SingleFieldList, ChipField } from "react-admin";
import WelcomeMessage from "../../components/WelcomeMessage";
import LocalizedField from "../../components/LocalizedField";

export const TalkShow = props => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <WelcomeMessage options={props.options} type={"edit"} />

        <TextField source="number" placeholder="01" />
        <TextField source="period" placeholder="January 2020" />
        <TextField source="year" placeholder="Year 1" />
        <ReferenceManyField label="Talk articles" reference="Article" target="talk">
          <SingleFieldList>
            <LocalizedField source="title" style={{ padding: "8px 16px", marginTop: "8px", background: "#ddd", borderRadius: 20, display: "inline-block" }} />
          </SingleFieldList>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
