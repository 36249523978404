import React from "react";
//@ts-ignore
import { Create, SimpleForm, TextInput } from "react-admin";
import ThreeInARow from "../../components/ThreeInARow";
import WelcomeMessage from "../../components/WelcomeMessage";
import ImagePicker from "../Article/Components/Composer/ImagePicker";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

export const AuthorCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <WelcomeMessage options={props.options} type={"create"} />
      <ThreeInARow>
        <TextInput fullWidth source="name" placeholder="Mario" />
        <TextInput fullWidth source="surname"placeholder="Rossi" />
        <TextInput
          fullWidth
          source="caption"
          label="Short description"
          placeholder="Project Manager"
        />
      </ThreeInARow>
      <ImagePicker style={{ marginTop: 16, marginBottom: 16 }} source="profile_image" label="Picture profile" />
    </SimpleForm>
  </Create>
);
