import * as React from "react";
//@ts-ignore
import { Edit, SimpleForm, TextInput, SelectInput } from "react-admin";
import { choicesAdminRoles } from "./list";
import ThreeInARow from "../../components/ThreeInARow";
import WelcomeMessage from '../../components/WelcomeMessage'

export const AdminEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <WelcomeMessage options={props.options} type={"edit"} />
      <ThreeInARow>
        <TextInput fullWidth disabled source="id" />
        <TextInput fullWidth source="name" />
        <TextInput fullWidth source="surname" />
        <TextInput fullWidth source="email" />
        <SelectInput
          fullWidth
          choices={choicesAdminRoles}
          optionText="name"
          optionValue="value"
          source="role"
        />
      </ThreeInARow>
    </SimpleForm>
  </Edit>
);
