import React from "react";
import { Grid, Button, IconButton, Typography, CircularProgress, Tooltip } from "@material-ui/core";

import DragHandleIcon from "@material-ui/icons/DragHandle";
import DeleteIcon from "@material-ui/icons/Delete";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
// import { mapArticleTypes } from '.';

const Topbar = props => {
  const { provided, name, onDelete, loading, action } = props;


  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="space-between"
      className="draggable-bar"
    >
      {
        <Grid item {...(provided?.dragHandleProps || {})}>
          <p
            className="draggable-bar-left"
            css={css`
              margin-top: 0;
              margin-bottom: 0;
              margin-left: 24px;
              line-height: 50px;
              color: ${provided ? "#003a78" : "rgba(0, 0, 0, 0.7)"};
              font-weight: 700;
            `}
          >
            {provided && <DragHandleIcon />}
            {name || ""}
          </p>
        </Grid>
      }
      {provided && (
        <>
          <Grid item>
            <Grid item>
              {loading != null ? (
                loading ? (
                  <CircularProgress size={20} style={{ marginRight: 16 }} />
                ) : (
                    <Typography children="Saved" />
                  )
              ) : null}
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <Tooltip title="Delete section" placement="left">
                  <IconButton
                    onClick={onDelete}
                    aria-label="Delete"
                    className="draggable-bar-delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Topbar;
