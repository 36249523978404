/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { AppBar, CircularProgress, Dialog, DialogContent, Grid, IconButton, ListItem, ListItemIcon, ListItemText, Slide, Toolbar, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import React, { useState } from "react";
import { FormDataConsumer, GET_LIST, ReferenceArrayInput, ReferenceInput, SelectArrayInput, SelectInput, showNotification, withDataProvider } from "react-admin";
import Theme from "../Theme";
import "./style.css";

const NoOneChoice = (props: any) => {
  let label: string = props.label || "";
  let prefix = "No";

  const regexVocals = /^(a|e|i|o|u)/i;
  const regexFemale = /(a|e|i)$/;

  if (!regexVocals.test(label)) {
    if (regexFemale.test(label)) {
      prefix += "a";
    }
  }

  return (
    <Grid container key="grid-container" alignItems="center">
      <Grid item xs={2}>
        <CloseIcon />
      </Grid>
      <Grid item xs>
        <Typography
          variant="subheading"
          key="no-one"
          children={prefix + " " + props?.label?.toLowerCase() || ""}
        />
      </Grid>
    </Grid>
  );
};

const AddablereferenceSelectInput = (props: any) => {
  const {
    label,
    source,
    reference,
    style,
    optionText,
    choices,
    fullWidth,
    multi,
    dispatch,
    dataProvider,
    ...rest
  } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");

  const InputComponent = multi ? ReferenceArrayInput : ReferenceInput;

  return (
    <span
      css={css`
        > div > div {
          height: 48px;
        }
      `}
    >
      <InputComponent label={label} source={source} resource={reference} reference={reference} {...rest}>
        <FormDataConsumer>
          {({ formData, ...rest }: any) => {
            //TODO sistemare
            // let choices = [...rest.choices, getAddButton(label, setOpenDialog)];
            let choices = [...rest.choices];

            if (!multi)
              choices = [
                { id: null, key: "boh", name: <NoOneChoice key="no-one" label={label} /> },
                ...choices
              ];

            choices = addSearchBarIfModulesAreTooMuch(
              rest,
              choices,
              label,
              searchFilter,
              setSearchFilter
            );

            const defaultStyleSelect = {
              width: fullWidth ? "100%" : 300
            };

            let choicesWithKey = choices.map((choice, index) => ({
              ...choice,
              key: "choice_" + index
            }));

            const newProps = {
              source,
              optionText,
              ...rest,
              choices: choicesWithKey.filter(
                (c: { name: string | any; id: any }) =>
                  notAString(c.name) || includeSearchFilter(c, searchFilter)
              ),
              style: style ? { ...style, ...defaultStyleSelect } : defaultStyleSelect
            };

            return multi ? (
              <SelectArrayInput {...newProps} />
            ) : (
              <SelectInput
                css={css`
                  li > span {
                    display: none;
                  }
                `}
                {...newProps}
              />
            );
          }}
        </FormDataConsumer>
      </InputComponent>

      <IFrameDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        label={label}
        reference={reference}
        dispatch={dispatch}
        dataProvider={dataProvider}
      />
    </span>
  );
};

export default withDataProvider(AddablereferenceSelectInput);

function Transition(props: any) {
  return <Slide direction="left" {...props} />;
}

interface IiFrameDialog {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  label: any;
  reference: any;
  dispatch: any;
  dataProvider: any;
}

const IFrameDialog = ({
  openDialog,
  setOpenDialog,
  label,
  reference,
  dispatch,
  dataProvider
}: IiFrameDialog) => {
  const [ready, setReady] = useState(false);

  return (
    <Dialog
      fullScreen
      style={{
        left: "10vw",
        backgroundColor: "#FAFAFA"
      }}
      TransitionComponent={Transition}
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      <AppBar style={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => {
              setOpenDialog(false);
              dataProvider(GET_LIST, "Admins", { page: 0, perPage: 25 }, { refresh: true })
                .then(() => {
                  dispatch(showNotification("Comment approved"));
                })
                .catch((e: any) => {
                  dispatch(showNotification(label + " added", "success"));
                });
            }}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="title" color="inherit" style={{ flex: 1 }}>
            Aggiungi {label}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent style={{ background: "#fafafa" }}>
        {!ready && (
          <Grid
            container
            item
            xs
            alignItems="center"
            alignContent="center"
            justify="center"
            css={css`
              height: 100%;
              position: absolute;
            `}
          >
            <Grid
              item
              xs
              css={css`
                text-align: center;
              `}
            >
              <CircularProgress />
            </Grid>
          </Grid>
        )}
        <iframe
          title="add"
          src={`/#/${reference}/create?iframe=true`}
          width={window.innerWidth * 0.85}
          height={window.innerHeight * 0.9}
          onLoad={(e: any) => {
            setTimeout(() => setReady(true), 500); // setReady(true);
          }}
          // position="relative"
          style={{
            border: "none",
            marginTop: "-64px",
            marginRight: "-150px",
            opacity: ready ? 1 : 0,
            transition: "opacity 500ms ease-in-out"
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

function includeSearchFilter(c: { name: any; id: any }, searchFilter: string): unknown {
  return c.name.toLowerCase().includes(searchFilter.toLowerCase());
}

function notAString(name: any): boolean {
  return typeof name !== "string";
}

function getAddButton(label: any, setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>) {
  return {
    id: "",
    name: (
      <ListItem
        css={css`
          background-color: ${Theme.palette.primary.main};
          border-radius: 10px;

          span,
          svg {
            color: white !important;
          }
          &:hover {
            background-color: ${Theme.palette.primary.light};
          }
        `}
        key={"addbutton-" + label}
        button
        // style={{ backgroundColor: "#ddd" }}
        disableRipple={true}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          // console.log(1717, event);
          setOpenDialog(true);
        }}
      >
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText primary={"add " + label} />
      </ListItem>
    )
  };
}

function addSearchBarIfModulesAreTooMuch(
  rest: any,
  choices: any[],
  label: any,
  filter: any,
  setFilter: any
) {
  if (rest.choices && rest.choices.length > 5)
    choices = [
      {
        id: "",
        name: (
          <ListItem
            key={"searchbar-" + label}
            button
            disableRipple={true}
            style={{
              paddingLeft: 0,
              backgroundColor: "white",
              borderBottom: "1px solid black"
            }}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              console.log(1717, event);
            }}
          >
            <ListItemIcon style={{ marginLeft: 8 }}>
              <SearchIcon />
            </ListItemIcon>
            <TextField
              fullWidth
              placeholder="Search"
              value={filter}
              onChange={({ target: { value } }) => setFilter(value)}
            />
          </ListItem>
        )
      },
      ...choices
    ];
  return choices;
}
