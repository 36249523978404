import React from "react";
import { languageContext } from "../../App";
export const optionRubric = (choice: any) => {
  return (
    <languageContext.Consumer>
      {({ locale }: any) => {

        let name;
        try {
          name = JSON.parse(choice.name)[locale];
        } catch (error) {
          name = choice.name;
        }

        return (
          <div
            key={name + "-rubric"}
            style={{
              padding: 10,
              color: "black",
              width: "100%",
              verticalAlign: "top",
              display: "flex",
              alignItems: "center",
              position: "relative"
            }}
          >
            {name && typeof name === "string" && (
              <div
                style={{
                  backgroundColor: choice.foreground_color,
                  width: "1em",
                  height: "1em",
                  borderRadius: 5,
                  display: "inline-block",
                  marginRight: 8
                }}
              />
            )}
            {name && <span style={{ width: "100%" }}>{name}</span>}
          </div>
        );
      }}
    </languageContext.Consumer>
  );
};
