import React, { useContext } from "react";
//@ts-ignore
import { Edit, SimpleForm, FormDataConsumer } from "react-admin";
import AddableReferenceWithSelectInput from "../../components/AddableReferenceSelectInput";
import LocalizedInput from "../../components/LocalizedInput";
import ThreeInARow from "../../components/ThreeInARow";
import WelcomeMessage from "../../components/WelcomeMessage";
import ArticleComposer from "./Components/Composer";
import { optionAuthor } from "./optionAuthor";
import { optionRender } from "./optionRender";
import { optionRubric } from "./optionRubric";
import { optionTalk } from "./optionTalk";
import { Button, Grid } from "@material-ui/core";
import { languageContext } from "../../App";

export const nominativeArticles = [
  "il",
  "lo",
  "l",
  "i",
  "gli",
  "la",
  "le",
  "un",
  "uno",
  "una",
  "un",
  "the",
  "a",
  "an",
  "el",
  "los",
  "las",
  "unos",
  "unas",
];

export const ArticleEdit = (props) => {
  const { locale, askForTranslation, shouldTranslate } = useContext(languageContext);

  return (
    <Edit {...props} title={"Edit Article"}>
      <SimpleForm className="no-card">
        <Grid container justify="space-between" style={{ width: "100%" }}>
          <WelcomeMessage options={props.options} type={"edit"} />

          {locale != "it" && (
            <Button
              variant="contained"
              color="secondary"
              disabled={shouldTranslate}
              onClick={() => {
                askForTranslation();
              }}
            >
              Translate from italian
            </Button>
          )}
        </Grid>

        {/* <TitleInput /> */}
        <LocalizedInput
          component="input"
          source="title"
          placeholder="Enter title"
          className="title-input"
          fullWidth
        />
        {/* <SubtitleInput /> */}
        <LocalizedInput
          component="input"
          source="subtitle"
          placeholder="Insert subtitle"
          className="subtitle-input"
          fullWidth
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const localizedTitle = JSON.parse(formData.title)[locale] || "";
            const lowercaseTitle = localizedTitle.toLowerCase();
            const noSpecialChars = lowercaseTitle.replace(/('|"|,|;|:|\.|\?|!|\/|=|\+)/g, " ");
            const splittedTitle = noSpecialChars.split(" ");
            const noArticlesTitle = splittedTitle.filter(
              (name) => !nominativeArticles.includes(name) && name.length > 0
            );
            const noSpacesTitle = noArticlesTitle.join("-");

            const parsedTitle = noSpacesTitle;

            return (
              <LocalizedInput
                disabled
                value={parsedTitle}
                controlled
                source="slug"
                label="Slug"
                placeholder="Slug"
                fullWidth
              />
            );
          }}
        </FormDataConsumer>

        <LocalizedInput component="switch" source="published" label="Published" />

        <ThreeInARow>
          <AddableReferenceWithSelectInput
            optionText={(choice) => optionAuthor(choice, props.client)}
            label="Autore"
            reference="Author"
            source="author"
          />
          <AddableReferenceWithSelectInput
            optionText={optionRubric}
            label="Rubric"
            reference="Rubric"
            source="rubric"
          />
          <AddableReferenceWithSelectInput
            optionText={optionTalk}
            label="Talk"
            reference="Talk"
            source="talk"
          />
        </ThreeInARow>

        <AddableReferenceWithSelectInput
          multi
          fullWidth
          optionText={optionRender}
          label="Topic"
          reference="Topic"
          source="topics"
        />

        <ArticleComposer {...props} />
      </SimpleForm>
    </Edit>
  );
};
