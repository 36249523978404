import React from "react";
//@ts-ignore
import { Datagrid, DateField, Filter, ImageField, List, TextField, TextInput } from "react-admin";

const ImageFilter = props => (
  <Filter {...props}>
    <TextInput label="Search for title" source="q" alwaysOn />
  </Filter>
);

export const ImageList = props => (
  <List filters={<ImageFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <ImageField className="rounded_image" label="Image" source="thumb_url" />
      <DateField source="created_at" showTime />
    </Datagrid>
  </List>
);
