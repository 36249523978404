import { Button, CircularProgress, Tooltip } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useState } from "react";
import { useApolloClient } from "react-apollo-hooks";
import { useDropzone } from "react-dropzone";
import MLDialog from "../../../../components/MLDialog";
import Topbar from "./TopBar";
const uuidv1 = require("uuid/v1");

const ImageCarousel = props => {
  const { provided, element, onSave } = props;
  const { content } = element;
  const [description, setDescription] = useState((content && content.description) || "");
  const [images, setImages] = useState((content && content.images) || []);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [selectedImageDescription, setSelectedImageDescription] = useState("");

  const client = useApolloClient(props.client);

  useEffect(() => {
    onSave({ description, images });
  }, [images, description]);

  useEffect(() => {

    if (content) {
      const stringContentImages = JSON.stringify(content.images);
      const stringContentDescription = content.description;

      const stringCurrentImages = JSON.stringify(images);
      const stringCurrentDescription = description;

      if (stringContentImages !== stringCurrentImages && stringCurrentDescription !== stringContentDescription) {
        setDescription((content && content.description) || "");
        setImages((content && content.images) || []);
        setSelectedImage(undefined);
      }

    }
  }, [content])


  const onDrop = useCallback(acceptedFiles => {
    setLoading(true);

    let imageType = /image.*/;

    acceptedFiles.forEach((image, index) => {
      if (image.type.match(imageType)) {
        let reader = new FileReader();
        reader.onload = (imageFile => {
          return e => {
            client
              .mutate({
                variables: { file: imageFile },
                mutation: gql(`
                mutation uploadImage($file: Upload!) {
                  uploadImage(file: $file) {
                    url
                    thumb_url
                  }
                }
              `)
              })
              .then(value => {
                setImages(state => [
                  ...state,
                  {
                    id: uuidv1(),
                    url: value.data.uploadImage.url,
                    thumb_url: value.data.uploadImage.thumb_url,
                    description: undefined
                  }
                ]);
                if (index === acceptedFiles.length - 1) setLoading(false);
              })
              .catch(err => {
                if (index === acceptedFiles.length - 1) setLoading(false);
                MLDialog.showSnackbar(
                  "Si è verificato un errore durante il caricamento dell'immagine, per favore riprova"
                );
              });
          };
        })(image);
        reader.readAsDataURL(image);
      } else {
        setLoading(false);
        MLDialog.showSnackbar("Per favore, carica un file di tipo immagine");
      }
    });

    // setLoading(false);

    // let image = acceptedFiles[0];
    // let imageType = /image.*/;
  }, []);

  const onImageDelete = index => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
    setSelectedImage(undefined);
    setSelectedImageDescription(undefined);
    MLDialog.showSnackbar("Immagine eliminata");
  };

  const toPreviousPosition = index => {
    if (index > 0) {
      let imagesList = [...images];
      let element = imagesList[index];
      imagesList[index] = imagesList[index - 1];
      imagesList[index - 1] = element;
      setImages(imagesList);
      if (index === selectedImage) setSelectedImageDescription(images[index - 1].description || "");
      else setSelectedImageDescription(images[index].description || "");
    }
  };

  const toNextPosition = index => {
    if (index < images.length - 1) {
      let imagesList = [...images];
      let element = imagesList[index];
      imagesList[index] = imagesList[index + 1];
      imagesList[index + 1] = element;
      setImages(imagesList);
      if (index === selectedImage) setSelectedImageDescription(images[index + 1].description || "");
      else setSelectedImageDescription(images[index].description || "");
    }
  };

  const onImageDescriptionChange = event => {
    setSelectedImageDescription(event.target.value);

    let currentImages = [...images];
    currentImages[selectedImage].description =
      event.target.value !== "" ? event.target.value : undefined;
    setImages(currentImages);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, disabled: loading });

  return (
    <div>
      <Topbar name="Carosello di immagini" provided={provided} onDelete={props.onDelete} />
      <div className="description">
        <p
          style={{ fontSize: 14, fontWeight: 500, lineHeight: "1.5", color: "rgba(0, 0, 0, .87)" }}
        >
          Inserisci qui la descrizione di questo carosello. Per inserire una descrizione per ogni
          immagine clicca sulle immagini e inserisci la relativa descrizione in basso.
        </p>
        <span>
          <input
            name="carousel-desc"
            component="input"
            type="text"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            placeholder="Inserisci una descrizione per questo carosello di immagini"
            className="image-description-input"
          />
        </span>
        {/* TODO add snack here */}
      </div>
      <div className="images-container">
        {images.map((image, index) => {
          return (
            <div className={`image ${index === selectedImage ? "selected" : ""}`} key={index}>
              <div className="container">
                <Tooltip title="Elimina immagine" placement="left">
                  <Button
                    variant="fab"
                    mini
                    className="image-delete-button"
                    aria-label="Delete"
                    color="primary"
                    onClick={() => onImageDelete(index)}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
                <Tooltip title="Sposta prima" placement="left">
                  <Button
                    disabled={index === 0}
                    variant="fab"
                    mini
                    className="image-previous-button"
                    aria-label="Delete"
                    color="primary"
                    onClick={() => toPreviousPosition(index)}
                  >
                    <KeyboardArrowLeftIcon />
                  </Button>
                </Tooltip>
                <Tooltip title="Sposta dopo" placement="right">
                  <Button
                    disabled={index === images.length - 1}
                    variant="fab"
                    mini
                    className="image-next-button"
                    aria-label="Delete"
                    color="primary"
                    onClick={() => toNextPosition(index)}
                  >
                    <KeyboardArrowRightIcon />
                  </Button>
                </Tooltip>
                <img
                  src={image.url}
                  onClick={() => {
                    setSelectedImage(index);
                    setSelectedImageDescription(images[index].description || "");
                  }}
                />
              </div>
            </div>
          );
        })}

        <div className="image">
          <div className="container" {...getRootProps()}>
            <input className="image-selector-drop" {...getInputProps()} />
            <div className={`upload ${isDragActive ? "active" : ""}`}>
              {!loading ? (
                <Button
                  variant="fab"
                  disabled={!isDragActive}
                  color="primary"
                  aria-label="Cloud Upload"
                >
                  <CloudUploadIcon />
                </Button>
              ) : (
                  <div>
                    <CircularProgress size={24} />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="description">
        <span style={{ opacity: selectedImage !== undefined ? 1 : 0 }}>
          <input
            disabled={selectedImage === undefined}
            type="text"
            placeholder="Inserisci una descrizione per questa immagine"
            className="image-description-input"
            onChange={onImageDescriptionChange}
            value={selectedImageDescription}
          />
        </span>
        {/* TODO add snack here */}
      </div>
    </div>
  );
};

export default ImageCarousel;
