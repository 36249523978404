import React from "react";
//@ts-ignore
import { Create, SimpleForm, TextInput } from "react-admin";
import ThreeInARow from "../../components/ThreeInARow";
import WelcomeMessage from "../../components/WelcomeMessage";
import LocalizedInput from "../../components/LocalizedInput";

export const TalkCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <WelcomeMessage options={props.options} type={"create"} />
      <ThreeInARow>
        <TextInput fullWidth source="number" placeholder="01" />
        <LocalizedInput fullWidth source="period" placeholder="January 2020" />
        <TextInput fullWidth source="year" placeholder="Anno 1" />
      </ThreeInARow>
    </SimpleForm>
  </Create>
);
