import * as React from "react";
//@ts-ignore
import { List, Datagrid, TextField, DateField, EmailField, SelectField } from "react-admin";

export const choicesAdminRoles = [
  { name: "Mabi Admin", value: "mabi-admin" },
  { name: "SIT Admin", value: "sit-admin" },
  { name: "Editore", value: "editor" }
];

export const AdminList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="surname" />
      <EmailField source="email" />
      <SelectField choices={choicesAdminRoles} optionText="name" optionValue="value" source="role" />
      <DateField source="created_at" showTime />
    </Datagrid>
  </List>
);
