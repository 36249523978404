import React from "react";
//@ts-ignore
import { Edit, SimpleForm, TextInput, ReferenceManyField, SingleFieldList, ChipField } from "react-admin";
import ThreeInARow from "../../components/ThreeInARow";
import WelcomeMessage from "../../components/WelcomeMessage";
import LocalizedField from "../../components/LocalizedField";
import LocalizedInput from "../../components/LocalizedInput";

export const TalkEdit = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <WelcomeMessage options={props.options} type={"edit"} />
        <ThreeInARow>
          <TextInput fullWidth source="number" placeholder="01" />
          <LocalizedInput fullWidth source="period" placeholder="January 2020" />
          <TextInput fullWidth source="year" placeholder="Year 1" />
        </ThreeInARow>
        <ReferenceManyField label="Articles in this talk" reference="Article" target="talk">
          <SingleFieldList>
            <LocalizedField source="title" style={{ padding: "8px 16px", marginTop: "8px", background: "#ddd", borderRadius: 20, display: "inline-block" }} />
          </SingleFieldList>
        </ReferenceManyField>
      </SimpleForm>
    </Edit>
  );
};
