import React from "react";
//@ts-ignore
import { Create, SimpleForm } from "react-admin";
import LocalizedInput from "../../components/LocalizedInput";
import WelcomeMessage from "../../components/WelcomeMessage";

export const TopicCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <WelcomeMessage options={props.options} type={"create"} />
      <LocalizedInput source="name" />
    </SimpleForm>
  </Create>
);
