import React from "react";
//@ts-ignore
import { Edit, SimpleForm } from "react-admin";
import LocalizedInput from "../../components/LocalizedInput";
import WelcomeMessage from "../../components/WelcomeMessage";

export const TopicEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <WelcomeMessage options={props.options} type={"edit"} />
      <LocalizedInput source="name"  />
    </SimpleForm>
  </Edit>
);
