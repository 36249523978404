import React from "react";
//@ts-ignore
import { Datagrid, DateField, Filter, List, TextField, TextInput } from "react-admin";
import { ColorField } from 'react-admin-color-input';
import LocalizedField from "../../components/LocalizedField";

const RubricFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by name" source="q" alwaysOn />
  </Filter>
);

export const RubricList = props => (
  <List filters={<RubricFilter />} {...props}>
    <Datagrid rowClick="edit">
      <LocalizedField source="name" />
      <TextField source="short_name" label="Slug" />
      <ColorField source="foreground_color" label="Primary color" />
      <ColorField source="background_color" label="Background color (reading)" />
      <DateField source="created_at" showTime />
    </Datagrid>
  </List>
);
