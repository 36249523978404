import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import React, { useRef, useState } from "react";

const Picker = props => {
  const [active, setActive] = useState(false);
  const selectorsContainer = useRef(null);

  const { sections, position, innerRef, provided } = props;

  let timer;

  const onMouseEnter = () => {
    clearTimeout(timer);
  };

  const onMouseLeave = () => {
    timer = setTimeout(() => {
      setActive(false);
    }, 7000);
  };

  return (
    <div
      className={`section-picker ${provided !== undefined ? "draggable" : ""} ${
        active ? "active" : ""
        }`}
      ref={innerRef}
      {...((provided || {}).draggableProps || {})}
    >
      <div className="container">
        <div className="placeholder" onClick={() => setActive(true)}>
          <p>
            Add block
            <AddCircleOutlineIcon />
          </p>
        </div>
        <div
          ref={selectorsContainer}
          className="selectors"
          onMouseOver={onMouseEnter}
          onMouseOut={onMouseLeave}
        >
          {sections.map((section, index) => (
            <div className="selector" key={index} style={{ width: `${100 / sections.length}%` }}>
              <div
                onClick={() => {
                  section.click(position);
                  setActive(false)
                }}
              >
                <p>
                  {section.icon}
                  {section.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Picker;
