/* eslint-disable react-hooks/exhaustive-deps */
import { TextField, FormControlLabel, Switch } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Field } from "redux-form";
import { languageContext } from "../../App";
import { ql } from "../../utils";

const OldFieldComp = (props) => {
  const { input, text, setText, locale, permLocale, setPermLocale, component } = props;

  let defaultLocalized = {};
  let localized;
  let choosenText = text;

  defaultLocalized[locale] = input.value || (component == "switch" ? false : "");
  try {
    localized = JSON.parse(input.value);
  } catch (error) {
    localized = defaultLocalized;
  }

  if (text != undefined && !permLocale) {
    if (text.length == 0) {
      setText(localized[locale]);
      choosenText = localized[locale];
    }
  }

  if (permLocale != locale) {
    setText(localized[locale] || (component == "switch" ? false : ""));
  }

  localized[locale] = choosenText;
  setPermLocale(locale);

  if ((input.value != undefined && input.value !== JSON.stringify(localized)) || !input.value) {
    input.onChange(JSON.stringify(localized));
  }

  return <div></div>;
};

const LocalizedInput = (props) => {
  const {
    source,
    record,
    label,
    required,
    component,
    placeholder,
    className,
    fullWidth,
    controlled,
    value,
    disabled,
  } = props;

  const { locale } = useContext(languageContext);
  const [permLocale, setPermLocale] = useState();

  const [text, setText] = useState(component == "switch" ? false : "");
  const { translate, shouldTranslate } = useContext(languageContext);

  useEffect(() => {
    if (controlled) {
      setText(value);
    }
  }, [value]);

  useEffect(() => {
    if (shouldTranslate && component !== "switch") {
      const parsedValue = JSON.parse(ql(record, source) || "{}");

      const localeValue = parsedValue["it"];

      if (localeValue) {
        translate(localeValue, locale).then((translation) => {
          setText(translation);
        });
      }
    }
  }, [shouldTranslate]);

  return (
    <span>
      {/* <p>{locale}</p> */}
      <Field
        name={source}
        component={(nprops) => (
          <OldFieldComp
            {...nprops}
            text={text}
            setText={setText}
            locale={locale}
            component={component}
            permLocale={permLocale}
            setPermLocale={setPermLocale}
          />
        )}
        type="text"
      />
      {component === "input" && (
        <input
          className={className}
          style={{ width: fullWidth ? "100%" : "auto" }}
          type="text"
          name={source}
          placeholder={placeholder}
          value={text}
          onChange={(event) => setText(event.target.value)}
        />
      )}
      {!component && (
        <TextField
          style={{ marginTop: 16, marginBottom: 8 }}
          fullWidth={fullWidth}
          className={className}
          disabled={disabled}
          required={required}
          name="name"
          type="text"
          placeholder={placeholder}
          label={label || source}
          value={text}
          onChange={(event) => setText(event.target.value)}
        />
      )}
      {component == "switch" && (
        <FormControlLabel
          control={
            <Switch
              checked={text}
              onChange={(event) => {
                setText(!text);
              }}
              value="checkedB"
              color="primary"
            />
          }
          label={label}
        />
        // <TextField
        //   style={{ marginTop: 16, marginBottom: 8 }}
        //   fullWidth={fullWidth}
        //   className={className}
        //   required={required}
        //   name="name"
        //   type="text"
        //   placeholder={placeholder}
        //   label={label || source}
        //   value={text}
        //   onChange={(event) => setText(event.target.value)}
        // />
      )}
    </span>
  );
};

export default LocalizedInput;
