import React from "react";
//@ts-ignore
import { Create, SimpleForm, TextInput, FileInput, FileField } from "react-admin";
import WelcomeMessage from "../../components/WelcomeMessage";

export const ImageCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <WelcomeMessage options={props.options} type={"create"} />
      <TextInput source="title" />
      <FileInput source="file" label="Any image">
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);
