const addUploadFeature = requestHandler => (type, resource, params) => {

  if ((type === 'CREATE' || type === 'UPDATE') && resource === 'Image') {
    // notice that following condition can be true only when `<ImageInput source="pictures" />` component has parameter `multiple={true}`
    // if parameter `multiple` is false, then data.pictures is not an array, but single object

    // console.log("PARAMS", params.data)
    if (params.data.file) {
      // only freshly dropped pictures are instance of File

      const { rawFile } = params.data.file;
      params.data.file = rawFile
      // console.log("FILE", params.data.file)

    }
    return requestHandler(type, resource, params);
  }
  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};

export default addUploadFeature;