import * as React from "react";
//@ts-ignore
import { Create, SimpleForm, TextInput, SelectInput } from "react-admin";
import { choicesAdminRoles } from "./list";
import ThreeInARow from "../../components/ThreeInARow";
import WelcomeMessage from "../../components/WelcomeMessage";

export const AdminCreate = props => {

  return (
    <Create {...props}>
      <SimpleForm>
        <WelcomeMessage options={props.options} type={"create"} />
        <ThreeInARow>
          <TextInput fullWidth source="name" />
          <TextInput fullWidth source="surname" />
          <TextInput fullWidth source="email" />
          <TextInput fullWidth source="password" />
          <SelectInput
            fullWidth
            choices={choicesAdminRoles}
            optionText="name"
            optionValue="value"
            source="role"
          />
        </ThreeInARow>
      </SimpleForm>
    </Create>
  );
};
