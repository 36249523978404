import React from "react";
//@ts-ignore
import { Datagrid, DateField, Filter, List, TextField, TextInput } from "react-admin";
import LocalizedField from "../../components/LocalizedField";

const TalkFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by number" source="q" alwaysOn />
  </Filter>
);

export const TalkList = props => (
  <List filters={<TalkFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="number"/>
      <LocalizedField source="period"/>
      <TextField source="year" />
      <DateField source="created_at" />
    </Datagrid>
  </List>
);
