import React from "react";
// @ts-ignore
import { Datagrid, DateField, List } from "react-admin";
import LocalizedField from "../../components/LocalizedField";

export const TopicList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <LocalizedField source="name" />
      <DateField source="created_at" showTime />
    </Datagrid>
  </List>
);
