/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import DmIcon from "@material-ui/icons/Book";
import ArticleIcon from "@material-ui/icons/Bookmark";
import IconAdmin from "@material-ui/icons/FolderSpecial";
import IconImage from "@material-ui/icons/Image";
import AuthorIcon from "@material-ui/icons/People";
import UserIcon from "@material-ui/icons/AccountBox";
import TopicIcon from "@material-ui/icons/Textsms";
import RubricIcon from "@material-ui/icons/ViewHeadline";
import Typography from "@material-ui/core/Typography";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { split } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { createUploadLink } from "apollo-upload-client";
import { getMainDefinition, isProduction } from "apollo-utilities";
import buildGraphQLProvider from "ra-data-graphql-simple";
import englishMessages from "ra-language-english";
import italianMessages from "ra-language-italian";
import React, { Component } from "react";
//@ts-ignore
import { Layout, AppBar, Admin, resolveBrowserLocale, Resource } from "react-admin";
import { ApolloProvider } from "react-apollo-hooks";
import "./App.css";
import AuthProvider from "./components/AuthProvider";
import { MLDialogProvider } from "./components/MLDialog";
import Theme from "./components/Theme";
import addUploadFeature from "./components/UploadEnhancer";
import { AdminCreate } from "./Schemas/Admin/create";
import { AdminEdit } from "./Schemas/Admin/edit";
import { AdminList } from "./Schemas/Admin/list";
import { ArticleCreate } from "./Schemas/Article/create";
import { ArticleEdit } from "./Schemas/Article/edit";
import { ArticleList } from "./Schemas/Article/list";
import { AuthorCreate } from "./Schemas/Author/create";
import { AuthorEdit } from "./Schemas/Author/edit";
import { AuthorList } from "./Schemas/Author/list";
import { ImageCreate } from "./Schemas/Image/create";
import { ImageList } from "./Schemas/Image/list";
import { RubricCreate } from "./Schemas/Rubric/create";
import { RubricEdit } from "./Schemas/Rubric/edit";
import { RubricList } from "./Schemas/Rubric/list";
import { TalkCreate } from "./Schemas/Talk/create";
import { TalkEdit } from "./Schemas/Talk/edit";
import { TalkList } from "./Schemas/Talk/list";
import { TalkShow } from "./Schemas/Talk/show";
import { TopicCreate } from "./Schemas/Topic/create";
import { TopicEdit } from "./Schemas/Topic/edit";
import { TopicList } from "./Schemas/Topic/list";
import { permissionLevel } from "./utils";
import Dashboard from "./scenes/Dashboard";
import { Route } from "react-router-dom";
import { UserList } from "./Schemas/User/list";

const messages = {
  it: italianMessages,
  en: englishMessages,
};
const i18nProvider = (locale) => messages[locale];

let mutation = `
mutation translate($text:String!, $lang: String!) {
    translate(text: $text, lang: $lang)
}          
`;

const token = localStorage.getItem("admin_sitandtalk_token");

const uploadLink = createUploadLink({
  headers: {
    authorization: token ? `Bearer ${token}` : "",
  },
  uri: process.env.REACT_APP_SERVER_URL,
});

const wsLink = new WebSocketLink({
  uri: `ws${isProduction() ? "s" : ""}://${process.env.REACT_APP_SERVER_HOST}/subscriptions`,
  options: {
    reconnect: false,
    connectionCallback: (error, result) => {
      console.log("SUBSCRIPTION", error, result);
    },
  },
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  uploadLink
);

const cache = new InMemoryCache();

const client = new ApolloClient({
  link: link,
  cache,
  resolvers: {},
});

export const languageContext = React.createContext({
  locale: "it",
  translate: (t, locale) => {
    return t;
  },
  shouldTranslate: false,
});

const SwitchLanguage = (props) => {
  const { value, onChange } = props;
  return (
    <div style={{ position: "fixed", top: 0, right: 150, zIndex: 1000, padding: 0 }}>
      <FormControl style={{ minWidth: 120, color: "white", marginTop: 8 }}>
        {/* <InputLabel htmlFor="language-selector">Lingua</InputLabel> */}
        <Select
          value={value}
          onChange={onChange}
          css={{
            "&:before": {
              borderColor: "white !important",
            },
            "&:after": {
              borderColor: "white",
            },

            svg: {
              fill: "white",
            },
          }}
          style={{ color: "white" }}
          inputProps={{
            name: "language",
            id: "language-selector",
          }}
        >
          <MenuItem value={"it"}>Italian</MenuItem>
          <MenuItem value={"en"}>English</MenuItem>
          <MenuItem value={"es"}>Spanish</MenuItem>
          <MenuItem value={"ro"}>Romanian</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataProvider: null,
      language: localStorage.getItem("admin_sitandtalk_language") || "it",
      shouldTranslate: false,
    };
  }

  componentDidMount() {
    buildGraphQLProvider({ client: client }).then((dataProvider) => {
      const uploadCapableProvider = addUploadFeature(dataProvider);
      this.setState({ dataProvider: uploadCapableProvider });
    });

    [0, 10, 20, 50, 100, 200, 500, 1000].forEach((time) =>
      setTimeout(() => {
        const drawer = document.querySelector("div[class^='MuiDrawer-docked']");
        const body = document.querySelector("div[class^='MuiDialogContent-root']");
        // console.log(drawer);
        if (body) {
          body.style.background = "#FAFAFA !important";
        }
        if (drawer) {
          const hash = window.location.hash;
          if (hash.toLowerCase().indexOf("iframe=true") > -1) {
            drawer.style.display = "none";
          }
        }
      }, time)
    );
  }

  translate = async (t, locale) => {
    if (!t || !locale) return;

    const r = await fetch(process.env.REACT_APP_SERVER_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: mutation,
        variables: { text: t.replace(/​/g, "†"), lang: locale },
      }),
    });

    const translation = (await r.json()).data.translate;

    return translation.replace(/†/g, "​");
  };

  askForTranslation = () => {
    this.setState((tmp) => ({ ...tmp, shouldTranslate: true }));

    setTimeout(() => {
      this.setState((tmp) => ({ ...tmp, shouldTranslate: false }));
    }, 2000);
  };

  renderAppLayout = (props) => (
    <Layout
      {...props}
      appBar={(props) => (
        <AppBar {...props}>
          <languageContext.Consumer>
            {({ locale }) => (
              <SwitchLanguage
                value={locale}
                onChange={(event) => {
                  localStorage.setItem("admin_sitandtalk_language", event.target.value);
                  this.setState({ language: event.target.value });
                }}
              />
            )}
          </languageContext.Consumer>

          <div style={{ flex: 1 }} />
        </AppBar>
      )}
    />
  );

  render() {
    const { dataProvider } = this.state;

    if (!dataProvider) {
      return <div>Loading</div>;
    }

    return (
      <>
        {/* <SwitchLanguage
          value={this.state.language}
          onChange={event => {
            localStorage.setItem("admin_sitandtalk_language", event.target.value);
            this.setState({ language: event.target.value });
          }}
        /> */}
        <languageContext.Provider
          value={{
            locale: this.state.language,
            translate: this.translate,
            askForTranslation: this.askForTranslation,
            shouldTranslate: this.state.shouldTranslate,
          }}
        >
          <ApolloProvider client={client}>
            <MLDialogProvider>
              <Admin
                customRoutes={[
                  <Route
                    exact
                    path="/import-users"
                    component={() => <Dashboard client={client} />}
                  />,
                  // <Route
                  //   exact
                  //   path="/calendar"
                  //   component={() => <Calendar client={client} />}
                  // />
                  // <Route exact path="/baz" component={Baz} noLayout />,
                ]}
                // dashboard={Dashboard}

                // locale={resolveBrowserLocale()}
                // i18nProvider={i18nProvider}
                theme={Theme}
                appLayout={this.renderAppLayout}
                dataProvider={dataProvider}
                authProvider={AuthProvider}
                dashboard={Dashboard}
              >
                {(permissions) => [
                  permissionLevel(permissions) > 10 && (
                    <Resource
                      name="Talk"
                      options={{ label: "SIT&Talk", single: "SIT&Talk" }}
                      create={TalkCreate}
                      edit={TalkEdit}
                      list={TalkList}
                      show={TalkShow}
                      icon={DmIcon}
                    />
                  ),
                  permissionLevel(permissions) > 10 && (
                    <Resource
                      name="Article"
                      options={{ label: "Articles", single: "article" }}
                      client={client}
                      create={(props) => <ArticleCreate client={client} {...props} />}
                      edit={(props) => <ArticleEdit client={client} {...props} />}
                      list={(props) => <ArticleList client={client} {...props} />}
                      icon={ArticleIcon}
                    />
                  ),
                  permissionLevel(permissions) > 10 && (
                    <Resource
                      name="Rubric"
                      options={{ label: "Rubrics", single: "rubric" }}
                      create={RubricCreate}
                      edit={RubricEdit}
                      list={RubricList}
                      icon={RubricIcon}
                    />
                  ),
                  permissionLevel(permissions) > 10 && (
                    <Resource
                      name="Topic"
                      options={{ label: "Topic", single: "topic" }}
                      create={TopicCreate}
                      edit={TopicEdit}
                      list={TopicList}
                      icon={TopicIcon}
                    />
                  ),
                  permissionLevel(permissions) > 10 && (
                    <Resource
                      name="User"
                      options={{ label: "Users", single: "user" }}
                      list={UserList}
                      icon={UserIcon}
                    />
                  ),
                  permissionLevel(permissions) > 10 && (
                    <Resource
                      name="Author"
                      options={{ label: "Authors", single: "author" }}
                      create={AuthorCreate}
                      edit={AuthorEdit}
                      list={AuthorList}
                      icon={AuthorIcon}
                    />
                  ),
                  permissionLevel(permissions) > 10 && (
                    <Resource
                      options={{ label: "Images", single: "image" }}
                      name="Image"
                      list={permissionLevel(permissions) > 20 && ImageList}
                      create={ImageCreate}
                      icon={IconImage}
                    />
                  ),
                  permissionLevel(permissions) > 50 && (
                    <Resource
                      name="Admin"
                      options={{ single: "admin" }}
                      list={AdminList}
                      icon={IconAdmin}
                      edit={AdminEdit}
                      create={AdminCreate}
                    />
                  ),
                ]}
              </Admin>
            </MLDialogProvider>
          </ApolloProvider>
        </languageContext.Provider>
      </>
    );
  }
}

/*edit={PostEdit} create={PostCreate} remove={Delete} */

export default App;
