import React from "react";
import VideoPicker from "./VideoPicker/VideoPicker";

const ArticleVideo = (props: any) => {
  const { value, provided } = props;

  return (
    <div className="draggable-container">
      <div className="draggable-content">
        <VideoPicker fullSize {...props} element={value} provided={provided} />
      </div>
    </div>
  );
};

export default ArticleVideo;
