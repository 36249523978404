/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState, useEffect, useContext, useCallback } from "react";

import { Grid } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Picker from "./Picker";
import { Field } from "redux-form";

import TextFieldsIcon from "@material-ui/icons/TextFields";
import PhotoIcon from "@material-ui/icons/Photo";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import VideoIcon from "@material-ui/icons/PersonalVideo";
import ArticleImage from "./ArticleImage";
import ArticleRichText from "./ArticleRichText";
import ArticleCarousel from "./ArticleCarousel";
import MLDialog from "../../../../components/MLDialog";
import { languageContext } from "../../../../App";
import ArticleVideo from "./ArticleVideo";

const types = {
  RICH_TEXT: "rich_text",
  IMAGE: "image",
  CAROUSEL: "carousel",
  VIDEO: "video",
};

const FieldComp = (props) => {
  const { input, body, setBody, permLocale, setPermLocale } = props;

  const { locale } = useContext(languageContext);

  let defaultLocalized = {};
  let localized;

  defaultLocalized[locale] = input.value || "";

  try {
    localized = JSON.parse(input.value);
  } catch (error) {
    localized = defaultLocalized;
  }

  let stringBody = JSON.stringify(body);
  let stringLocale = JSON.stringify(localized[locale]);

  if (stringLocale !== stringBody && permLocale === locale) {
    localized[locale] = body;
    input.onChange(JSON.stringify(localized));
  }

  if (permLocale !== locale) {
    setBody(localized[locale] || []);
    setPermLocale(locale);
  }

  return <div></div>;
};

const ArticleComposer = (props) => {
  const [body, setBody] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [permLocale, setPermLocale] = useState();

  const translateBody = useCallback(async () => {
    const parsedBody = JSON.parse(props.record["body"]);
    const fromLanguage = parsedBody["it"] || [];

    for (const item of fromLanguage) {
      switch (item.type) {
        case "rich_text":
          item["content"]["text"] = await translate(item["content"]["text"], locale);
          break;
        case "image":
          item["content"]["caption"] = await translate(item["content"]["caption"], locale);
          break;
        case "carousel":
          item["content"]["description"] = await translate(item["content"]["description"], locale);
          const newImages = [];
          for (const image of item["content"]["images"]) {
            const imageTranslated = { ...image };
            imageTranslated.description = await translate(image["description"], locale);
            newImages.push(imageTranslated);
          }
          item["content"]["images"] = newImages;
          break;
        case "video":
          item["content"]["caption"] = await translate(item["content"]["caption"], locale);
          break;
        default:
          break;
      }
    }
    setBody(fromLanguage);
  }, [props]);

  const { locale, translate, shouldTranslate } = useContext(languageContext);
  useEffect(() => {
    if (shouldTranslate) {
      translateBody();
    }
  }, [shouldTranslate]);

  const sections = [
    {
      id: 0,
      name: "Text",
      icon: <TextFieldsIcon />,
      click: (position) => add(0, position),
    },
    {
      id: 1,
      name: "Image",
      icon: <PhotoIcon />,
      click: (position) => add(1, position),
    },
    {
      id: 2,
      name: "Carousel",
      icon: <ViewCarouselIcon />,
      click: (position) => add(2, position),
    },
    {
      id: 3,
      name: "Video",
      icon: <VideoIcon />,
      click: (position) => add(3, position),
    },
  ];

  const add = (id, position) => {
    // console.log("🍕 Position", position);
    let element;
    let key = "drag-" + Date.now();
    switch (id) {
      case 0:
        element = {
          type: types.RICH_TEXT,
          key: key,
        };
        break;
      case 1:
        element = {
          type: types.IMAGE,
          key: key,
        };
        break;
      case 2:
        element = {
          type: types.CAROUSEL,
          key: key,
        };
        break;
      case 3:
        element = {
          type: types.VIDEO,
          key: key,
        };
        break;
      default:
        break;
    }
    if (position === undefined) {
      setBody([...body, element]);
    } else {
      const newBody = [...body];
      newBody.splice(position + 1, 0, element);
      setBody(newBody);
    }
  };

  const onDelete = (index) => {
    MLDialog.showModal(
      "Delete section",
      "Do you really want to delete this section of the article? The section cannot be restored after deleting.",
      {
        onNegativeClick: () => {},
        negativeText: "No",
        onPositiveClick: () => {
          const newBody = [...body];
          newBody.splice(index, 1);
          setBody(newBody);
        },
        positiveText: "Sì",
      }
    );
  };

  const onDragStart = () => {
    setDragging(true);
  };

  const onDragEnd = (result) => {
    setDragging(false);
    // Reording the array

    const { destination, source } = result;

    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    let reordered = [...body];
    let section = reordered[source.index];
    reordered.splice(source.index, 1);
    reordered.splice(destination.index, 0, section);

    setBody(reordered);
  };

  return (
    <div>
      <Field
        name="body"
        component={(nprops) => (
          <FieldComp
            {...nprops}
            body={body}
            setBody={setBody}
            permLocale={permLocale}
            setPermLocale={setPermLocale}
          />
        )}
        type="text"
      />
      <p
        children={"Body of the article"}
        css={css`
          font-size: 24px;
          font-weight: 700;
          margin-top: 72px;
        `}
      />
      <p
        children={"Enter the body of the article here."}
        css={css`
          font-size: 16px;
          max-width: 720px;
        `}
      />
      <p
        children={
          "You can add sections with the button below and move the sections to the position you prefer by dragging them while holding down the mouse on the drag button (icon with two horizontal bars)."
        }
        css={css`
          font-size: 16px;
          max-width: 720px;
        `}
      />
      <p
        children={
          "You can also insert a section between two sections with the add button that you find between one section and another."
        }
        css={css`
          font-size: 16px;
          max-width: 720px;
          margin-bottom: ${body.length === 0 ? 24 : -48}px;
        `}
      />
      <Grid container>
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <Droppable droppableId="article-body-droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {body.map((item, index) => {
                  const itemKey = `${item.key}-container`;
                  return (
                    <div key={itemKey}>
                      <Draggable draggableId={itemKey} index={index} key={itemKey}>
                        {(provided, snapshot) => {
                          const otherProps = {
                            provided: provided,
                            key: item.key,
                            onDelete: () => onDelete(index),
                            onSave: (value) => {
                              let tmpBody = [...body];
                              tmpBody[index].content = value;
                              setBody(tmpBody);
                            },
                          };
                          return (
                            <div
                              className={`${snapshot.isDragging ? "is-dragging" : ""}`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              // {...provided.dragHandleProps}
                            >
                              {item.type === types.RICH_TEXT ? (
                                <ArticleRichText value={item} {...props} {...otherProps} />
                              ) : item.type === types.IMAGE ? (
                                <ArticleImage value={item} {...props} {...otherProps} />
                              ) : item.type === types.CAROUSEL ? (
                                <ArticleCarousel value={item} {...props} {...otherProps} />
                              ) : item.type === types.VIDEO ? (
                                <ArticleVideo value={item} {...props} {...otherProps} />
                              ) : null}
                            </div>
                          );
                        }}
                      </Draggable>

                      {index !== body.length - 1 && (
                        <Draggable
                          draggableId={`${itemKey}-picker`}
                          index={index}
                          isDragDisabled={true}
                          key={`${itemKey}-picker`}
                        >
                          {(provided, snapshot) => (
                            <div
                              style={{
                                height: 0,
                                transform: dragging ? "translateY(0px)" : "translateY(0px)",
                                display: dragging ? "none" : "block",
                              }}
                            >
                              <Picker
                                sections={sections}
                                position={index}
                                innerRef={provided.innerRef}
                                provided={provided}
                              />
                            </div>
                          )}
                        </Draggable>
                      )}
                    </div>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Picker sections={sections} />
        </DragDropContext>
      </Grid>
    </div>
  );
};

export default ArticleComposer;
