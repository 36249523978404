/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Button, CircularProgress } from "@material-ui/core";
import PhotoIcon from "@material-ui/icons/Photo";
import gql from "graphql-tag";
import { useEffect, useRef, useState } from "react";
import { useApolloClient } from "react-apollo-hooks";
import RichTextEditor, { EditorValue } from "react-rte";
import TopBar from "./TopBar";

const uuidv1 = require("uuid/v1");

const INLINE_STYLE_BUTTONS = [
  { label: "Bold", style: "BOLD", className: "boilde" },
  { label: "Italic", style: "ITALIC" },
  /*{ label: 'Strikethrough', style: 'STRIKETHROUGH' }, { label: 'Monospace', style: 'CODE' },*/ {
    label: "Underline",
    style: "UNDERLINE",
  },
];

const BLOCK_TYPE_DROPDOWN = [
  { label: "Normal", style: "unstyled" },
  { label: "Heading Large", style: "header-one" },
  { label: "Heading Medium", style: "header-two" },
  {
    label: "Heading Small",
    style: "header-three",
  } /*, { label: 'Code Block', style: 'code-block' }*/,
];
const BLOCK_TYPE_BUTTONS = [
  { label: "UL", style: "unordered-list-item" },
  { label: "OL", style: "ordered-list-item" },
  { label: "Blockquote", style: "blockquote" },
];

export const DefaultToolbarConfig: any = {
  display: [
    "BLOCK_TYPE_DROPDOWN",
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "HISTORY_BUTTONS",
  ], // 'IMAGE_BUTTON' to add image button
  INLINE_STYLE_BUTTONS: INLINE_STYLE_BUTTONS,
  BLOCK_TYPE_DROPDOWN: BLOCK_TYPE_DROPDOWN,
  BLOCK_TYPE_BUTTONS: BLOCK_TYPE_BUTTONS,
};

interface IImage {
  id: string;
  url: string;
  thumb_url: string;
}

export default (props: any) => {
  const { element, onSave, name, provided, ...others } = props;

  const { content } = element;
  // const { locale } = useContext(languageContext);

  const [focused, setFocused] = useState(false);

  const [text, setText] = useState<EditorValue>(
    content
      ? //TODO rimuovere dopo refactor
        RichTextEditor.createValueFromString(content?.text || content, "markdown")
      : RichTextEditor.createEmptyValue()
  );
  const [image, setImage] = useState<any>(content?.image);

  // console.log(focused);

  useEffect(() => {
    // console.log("💚", "------");
    // console.log("💚", focused);
    // console.log("💚", content);
    // console.log("💚", text);
    // console.log("💚", element?.content?.text);
    if (!focused) {
      setText(
        content
          ? //TODO rimuovere dopo refactor
            RichTextEditor.createValueFromString(content?.text || content, "markdown")
          : RichTextEditor.createEmptyValue()
      );
    }
    setImage(content?.image);
  }, [content]);

  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);

  let timeout = useRef<any>();

  const client = useApolloClient(props.client);

  const lastValue = useRef<{ text: string; image: IImage | null }>({
    text: content?.text || content || " ",
    image: image || null,
  });

  const onChange = (value: { text?: EditorValue; image?: IImage | null }) => {
    //! changed text
    if (value.text) {
      setText(value.text);
      const mdText = value.text.toString("markdown");
      // console.log("💚💙", mdText);
      lastValue.current.text = mdText;
    }

    //! changed image
    if (value.image !== undefined) {
      setImage(value.image);
      lastValue.current.image = value.image;
    }

    //! debounce callback function --> onSave
    if (timeout.current) clearTimeout(timeout.current);

    setLoading(true);
    timeout.current = setTimeout(() => {
      setLoading(false);
      const saveObj = {
        text: lastValue?.current?.text?.trim()?.length ? lastValue.current.text : " ",
        image: lastValue?.current?.image || null,
      };
      onSave(saveObj);
      // console.log(321, lastValue.current, saveObj);
    }, 500);
  };

  const handleImageInput = (e: any) => {
    setImageLoading(true);
    if (e.target.files.length === 1) {
      let image = e.target.files[0];
      let imageType = /image.*/;
      if (image.type.match(imageType)) {
        let reader = new FileReader();
        reader.onload = ((imageFile) => {
          return (e: any) => {
            client
              .mutate({
                variables: { file: imageFile },
                mutation: gql(`
                  mutation uploadImage($file: Upload!) {
                    uploadImage(file: $file) {
                      url
                      thumb_url
                    }
                  }
                `),
              })
              .then((value) => {
                onChange({
                  // text,
                  image: {
                    id: uuidv1(),
                    url: value.data.uploadImage.url,
                    thumb_url: value.data.uploadImage.thumb_url,
                  },
                });
                setImageLoading(false);
              })
              .catch((err) => {
                // console.log("fallimento, here's the mistake");
                console.error(err);
                setImageLoading(false);
              });
          };
        })(image);
        reader.readAsDataURL(image);
      } else {
        console.log("File type not supported");
        setImageLoading(false);
      }
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <TopBar loading={loading} name={name} provided={provided} {...others} />

      <RichTextEditor
        css={
          image &&
          css`
            div[data-contents="true"] {
              div[class^="RichTextEditor"] {
                position: relative;
                clear: left;
                &:before {
                  content: "";
                  float: right;
                  width: 300px;
                  height: auto;
                }
              }
              div[class^="RichTextEditor"]:first-of-type {
                position: relative;
                clear: left;
                &:before {
                  content: "";
                  background-image: url("${image.url}");
                  background-position: 50% 50%;
                  background-size: cover;
                  border-radius: 20px;
                  float: right;
                  width: 300px;
                  height: 300px;
                }
              }
            }
          `
        }
        toolbarConfig={DefaultToolbarConfig}
        value={text}
        onChange={(value) =>
          onChange({
            text: value,
            //image
          })
        }
        className="rte"
        toolbarClassName="rte-toolbar"
        editorClassName="rte-editor"
        //@ts-ignore
        onFocus={() => setFocused(true)}
        //@ts-ignore
        onBlur={() => setFocused(false)}
      />

      {image && (
        <span
          css={css`
            /* background-image: url("${image.url}");
            background-size: cover;
            background-position: 50% 50%;
            position: absolute;
            top: 100px;
            right: 0;
            width: 300px;
            height: 300px; */
          `}
        />
      )}

      <Button
        size="small"
        className="upload-image-button"
        onClick={() => onChange({ image: null })}
      >
        {imageLoading ? (
          <CircularProgress size={20} style={{ marginRight: 8, marginTop: 3 }} />
        ) : (
          <PhotoIcon style={{ color: "rgba(0, 0, 0, .54)", marginRight: 8 }} />
        )}

        {image ? "Remove image" : "Upload image"}

        {!image && (
          <input
            accept="image/*"
            className="hidden-input"
            type="file"
            onChange={handleImageInput}
          />
        )}
      </Button>
    </div>
  );
};
