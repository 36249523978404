import React from "react";
import { Grid } from "@material-ui/core";

export interface IThreeInARow {
  children: [React.ReactChildren];
}

const ThreeInARow = (props: IThreeInARow) => {
  const { children, ...others } = props;

  let maxSm: any = Math.floor(12 / children.length);
  if (maxSm < 4) maxSm = 4;

  return (
    <Grid container style={{ width: "auto" }} spacing={40}>
      {children.map((child, i) => (
        <Grid key={"three-in-a-row-" + i} item xs={12} sm={maxSm} {...others}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
};

export default ThreeInARow;
