import React from "react";
//@ts-ignore
import { Create, SimpleForm, TextInput } from "react-admin";
import { ColorInput } from "react-admin-color-input";
import { isSmallDevice } from "../../utils";
import ThreeInARow from "../../components/ThreeInARow";
import WelcomeMessage from "../../components/WelcomeMessage";
import LocalizedInput from "../../components/LocalizedInput";

export const smallScreenStyle = {
  width: "100%"
};

export const RubricCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <WelcomeMessage options={props.options} type={"create"} />
        <ThreeInARow>
          <LocalizedInput fullWidth label="Nome" source="name" required />
          <TextInput fullWidth label="Slug" source="short_name" required />
          <ColorInput
            style={smallScreenStyle}
            label="Colore primario"
            source="foreground_color"
            required
          />
          <ColorInput
            style={smallScreenStyle}
            label="Colore sfondo (lettura)"
            source="background_color"
            required
          />
        </ThreeInARow>
      </SimpleForm>
    </Create>
  );
};
