import React from 'react';
//@ts-ignore
import { Datagrid, DateField, Filter, ImageField, List, ReferenceField, TextField, TextInput } from 'react-admin';

const AuthorFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by name" source="name" alwaysOn />
    <TextInput label="Search by surname" source="surname" alwaysOn />
  </Filter>
);

export const AuthorList = props => (
  <List filters={<AuthorFilter />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="surname" />
      <TextField source="caption" label="Short description" />
      <ReferenceField source="profile_image" reference="Image" linkType={false}>
        <ImageField className="small_circle_image" source="thumb_url" />
      </ReferenceField>
      <DateField source="created_at" />
    </Datagrid>
  </List>
);